import VisuallyHidden from '@reach/visually-hidden';
import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import attachmentIcon from '../assets/attachment_icon.svg';
import closeIcon from '../assets/close_icon.svg';
import { COLORS } from '../constants';
import { FormError } from '../react-app-env';

const DropzoneEl = styled.section`
  border-radius: 4px;
  border-style: dashed;
  border: ${({ hasErrors }: { hasErrors: boolean }) =>
    hasErrors ? `2px dashed ${COLORS.errorColor}` : '1px dashed #d9e1ec'};
  /* padding: 18px 0; */
  background-color: ${({ hasErrors }: { hasErrors: boolean }) =>
    hasErrors ? `${COLORS.secondaryErrorColor}` : `#fdfdfe`};
  margin-bottom: 20px;
  box-sizing: border-box;

  span.dropzone-text {
    cursor: pointer;
    padding: 18px 39px 18px 79px;
    margin: 0;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #98a8b7;
    letter-spacing: -0.17;
    line-height: 24px;

    .file-details-wrapper {
      background-color: #e9eff7;
      border-radius: 2px;
      padding: 0 6px 0 7px;
      display: flex;
    }

    .blue-text {
      color: #2c7ef8;
    }

    p {
      margin: 0;
    }

    .close-icon {
      margin-left: 10px;
      cursor: pointer;
    }

    .attachment-icon {
      margin-right: 10px;
    }
  }
`;

interface Props {
  name: string;
  value: File | null;
  onChange: (name: string, value: File | null) => void;
  acceptableMimeTypes: string[];
  errors: FormError;
}

type OnChangeInputs = {
  name: string;
  value: File;
};

export class FileUpload extends React.Component<Props> {
  state = {
    fileTypeError: '',
  };

  _checkIfFileIsOfAccetableType = (file: File): Promise<OnChangeInputs> =>
    new Promise((resolve, reject): void => {
      // file.type = application/zip | application/png etc
      const fileMimeType = file.type.split('/')[1];
      const errorMsg = `The uploaded file should be one of the following: ${this.props.acceptableMimeTypes.join(
        ', ',
      )}`;
      const fileIsAcceptable = this.props.acceptableMimeTypes.includes(
        fileMimeType,
      );
      fileIsAcceptable ? resolve() : reject(errorMsg);
    });

  _checkFileSize = (file: File): Promise<OnChangeInputs> =>
    new Promise((resolve, reject): void => {
      const limit = Number(process.env.REACT_APP_FILE_SIZE_LIMIT);
      const errorMsg = `Please upload a file less than ${limit / 1000}kb`;

      file.size < limit ? resolve() : reject(errorMsg);
    });

  _onDrop = ([file]: File[]) => {
    Promise.all([
      this._checkIfFileIsOfAccetableType(file),
      this._checkFileSize(file),
    ])
      .then(() => {
        this.setState({ fileTypeError: '' });
        this.props.onChange(this.props.name, file);
      })
      .catch((errorMsg) => {
        // we need to output the error from the check
        this.setState({ fileTypeError: errorMsg });
        // this.props.onError()
      });
    // Then we check on some lifecycle if we have a file, if we do, we set the name equal to the file
    // we do same for the size
  };

  _getDisplayFilename = (
    filename: string,
    characterLimit: number = 29,
  ): string => {
    // We need to return a string that is 29 characters in length but must include the extension at the tail end
    if (filename.length > 30) {
      const [extensionString, ...fileNameArray] = filename.split('.').reverse();
      const fileNameWithoutExtensionString = fileNameArray.reverse().join('');

      // We want three dots at the end of the truncated string;
      const ellipsisCount = 3;
      const characterCountRemaining =
        characterLimit - extensionString.length - ellipsisCount;
      const truncatedString = [
        fileNameWithoutExtensionString.substring(0, characterCountRemaining),
        new Array(ellipsisCount).fill('.').join(''),
        extensionString,
      ];
      return truncatedString.join('');
    } else {
      return filename;
    }
  };

  _onRemoveFile = () => {
    this.props.onChange(this.props.name, null);
  };

  render() {
    // if this is null, we know we don't have a file
    const { errors, value: uploadedFile, name } = this.props;
    const { fileTypeError } = this.state;

    return (
      <React.Fragment>
        <Dropzone data-cy="drag-and-drop" onDrop={this._onDrop}>
          {({ getRootProps, getInputProps }) => (
            <DropzoneEl hasErrors={!!errors[name] || !!fileTypeError}>
              <div {...getRootProps()}>
                <VisuallyHidden>
                  <label htmlFor={name}>Email</label>
                </VisuallyHidden>
                <input {...getInputProps()} />
                <span className="dropzone-text">
                  {!uploadedFile ? (
                    <>
                      <img
                        className="attachment-icon"
                        src={attachmentIcon}
                        alt="add an attachment icon"
                      />
                      {errors[name] ? (
                        <p style={{ color: COLORS.errorColor }}>
                          {errors[name]}
                        </p>
                      ) : (
                        <p>
                          <span className="blue-text">Add ZIP file </span> or
                          drop it here...
                        </p>
                      )}
                    </>
                  ) : (
                    <div className="file-details-wrapper">
                      <p>
                        <span className="blue-text">
                          {this._getDisplayFilename(uploadedFile.name)}
                        </span>{' '}
                        {Math.floor(uploadedFile.size / 1000)}kb
                      </p>
                      <img
                        onClick={this._onRemoveFile}
                        className="close-icon"
                        src={closeIcon}
                        alt="clear the selected file"
                      />
                    </div>
                  )}
                </span>
              </div>
            </DropzoneEl>
          )}
        </Dropzone>
        {fileTypeError && (
          <p
            style={{
              color: COLORS.errorColor,
              fontSize: 12,
              fontWeight: 'bold',
              marginTop: -10,
            }}
          >
            {fileTypeError}
          </p>
        )}
      </React.Fragment>
    );
  }
}
