import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { Card, CardHeader, Logo, PageWrapper } from '../components';
import { UploadForm } from '../components/UploadForm';
import ErrorBoundary from '../components/ErrorBoundary';
import { fetchFromHasura } from '../util/fetchFromHasura';

const UploadPageEl = styled.div`
	margin: 0 auto;
	width: max-content;
`;

interface Params {
	developerId: string;
	assessmentId: string;
}

interface Props extends RouteComponentProps<Params> {
	user: {
		_id: string;
		email: string;
		display_name: string;
	} | null;
}

const UploadPage: React.FC<Props> = ({ history, match, user }) => {
	const {
		params: { developerId, assessmentId: developerAssessmentId },
	} = match;

	const limitInMb = Number(process.env.REACT_APP_FILE_SIZE_LIMIT) / 1000000;
	const fileSizeLimit = isNaN(limitInMb) ? false : limitInMb;
	const [assessmentId, setAssessmentId] = useState<string | null>(null)

	useEffect(() => {
		fetchFromHasura({
			query: `query getDeveloperAssessmentById($developerAssessmentId: uuid!) {
			developer_assessments (where: { id: {_eq: $developerAssessmentId }}) {
				id
				assessment_id
			}
		}`, variables: { developerAssessmentId }
		}).then(({ data, errors }) => {
			if (errors || !data?.developer_assessments?.length) {
				history.replace('/upload')
				return;
			}
			setAssessmentId(data.developer_assessments[0].assessment_id)
		})
	}, [history, developerAssessmentId])

	const renderBodyText = () => (
		<p className="page-subtitle">
			Please upload a zipped version of your project here and be sure not
			to include the node_modules folder.
			{fileSizeLimit &&
				` Maximum upload file size allowed is ${fileSizeLimit}MB.`}
		</p>
	);

	const handleRedirect = () => history.push('/upload_success');

	return (
		<PageWrapper>
			<UploadPageEl>
				<Card>
					<CardHeader
						icon={Logo}
						titleText="Upload Your Code Challenge"
						renderBodyText={renderBodyText}
					/>
					<ErrorBoundary>
						<UploadForm
							generic={false}
							user={user}
							developerId={developerId}
							assessmentId={assessmentId}
							developerAssessmentId={developerAssessmentId}
							handleRedirect={handleRedirect}
						/>
					</ErrorBoundary>
				</Card>
			</UploadPageEl>
		</PageWrapper>
	);
};

export default UploadPage;
