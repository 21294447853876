export const fetchFromHasura = ({
	query,
	variables,
}: {
	query: string;
	variables?: Record<string, string | undefined | null>;
}) =>
	fetch(String(process.env.REACT_APP_API), {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
		},
		body: JSON.stringify({
			query,
			variables,
		}),
	}).then(r => r.json());
