import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import App from './App';
import './assets/index.css';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		maxBreadcrumbs: 50,
		attachStacktrace: true,
	});
}

ReactDOM.render(<App />, document.getElementById('root'));
