import VisuallyHidden from '@reach/visually-hidden';
import React, {
	Component,
	KeyboardEvent,
	lazy,
	MouseEvent,
	ReactNode,
	Suspense,
} from 'react';
import styled from 'styled-components';

import { COLORS } from '../constants';
import { FormError } from '../react-app-env';
import { Button, FileUpload, TextInput, CheckBoxInput } from './';
import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
import NDAModal from '../components/NDAModal';
import { Select } from './Select';
import { FieldGroup } from './FieldGroup';
import { fetchFromHasura } from '../util/fetchFromHasura';
import { updateCodeUrlForExistingAssessment } from '../queries/updateCodeUrlForExistingDeveloperAssessment';
import { updateCodeUrlForNewAssessment } from '../queries/updateCodeUrlForNewAssessment';

const getUserFromEmail = async (email: string) =>
	fetchFromHasura({
		query: `query getDeveloper($email: String!) {
				developers(where: {email: {_eq: $email }}) {
					id
				}
			}`,
		variables: { email },
	}).then(data => data);

const getAssessments = async () =>
	fetchFromHasura({
		query: `query getAssessments {
				assessments(where: {name: {_in: ["Node.js Acronym Code Challenge - 2021", "Trivia React Web Code Challenge - 2021", "Trivia React Native Code Challenge - 2021"]}, assessment_type: {value: {_eq: "CODE_CHALLENGE"}}, active: {_eq: true}}) {
				  id
				  name
				}
			  }`,
	}).then(data => data);

const UploadTimedOutErrorMessage = lazy(() =>
	import(
		/* webpackChunkName: "timeout-error-message" */ './UploadTimedOutErrorMessage'
	)
);

const FormEl = styled.form`
	margin: 30px 0 22px;

	p.form-small-text {
		text-align: center;
		font-size: 0.8rem;
		font-weight: bold;
		color: #708096;

		&.form-error-text {
			color: ${COLORS.errorColor};
		}
	}
`;

interface State {
	email: string;
	assessments: { label: string; value: string }[];
	assessmentId: string;
	developerId: null | string;
	codeChallenge: File | null;
	formErrorMsg: ReactNode;
	errors: FormError;
	loading: boolean;
	agreeToTerms: boolean;
	privacyPolicyModal: boolean;
	ndaPolicyModal: boolean;
}

interface Props {
	generic: boolean;
	handleRedirect: () => void;
	developerId?: string;
	developerAssessmentId?: string;
	assessmentId?: string | null;
	user: {
		_id: string;
		email: string;
		display_name: string;
	} | null;
}

const RequiredFields = [
	'assessmentId',
	'email',
	'agreeToTerms',
	'codeChallenge',
];

export class UploadForm extends Component<Props, State> {
	state: State = {
		assessments: [],
		assessmentId: this.props.assessmentId || '',
		developerId: null,
		email: '',
		formErrorMsg: '',
		codeChallenge: null,
		agreeToTerms: false,
		privacyPolicyModal: false,
		ndaPolicyModal: false,
		errors: {
			email: null,
			codeChallenge: null,
			agreeToTerms: null,
		},
		loading: false,
	};

	componentDidMount = () => {
		getAssessments().then(r => {
			if (r?.errors) {
				throw new Error('Cannot get assessments.');
			}

			this.setState(p => ({
				...p,
				assessmentId: r.data?.assessments?.[0]?.id ?? p.assessmentId,
				assessments:
					r.data?.assessments?.map(
						(a: { id: string; name: string }) => ({
							label: a.name,
							value: a.id,
						})
					) ?? [],
			}));
		});
	};

	_validateForm = ({
		email,
		codeChallenge,
		agreeToTerms,
	}: State): FormError => {
		const errors: FormError = {
			email: null,
			codeChallenge: null,
			agreeToTerms: null,
		};

		if (this.props.generic) {
			errors.email = !email ? 'Please enter an email.' : null;
		}
		errors.codeChallenge = !codeChallenge
			? 'Please upload a zip file with your code challenge.'
			: null;
		errors.agreeToTerms = !agreeToTerms
			? 'Please agree to the NDA and privacy policy.'
			: null;

		return errors;
	};

	_onSubmitForm = async (e?: MouseEvent | KeyboardEvent) => {
		e?.preventDefault();

		this.setState({ formErrorMsg: '' });
		const errors = this._validateForm(this.state);
		const { codeChallenge } = this.state;

		const fileContent = await new Promise((resolve, reject) => {
			try {
				if (!codeChallenge) {
					console.warn('No file selected.');
					return;
				}
				const reader = new FileReader();
				reader.readAsDataURL(codeChallenge);
				reader.onload = () =>
					resolve(String(reader.result).split('base64,')[1]);
			} catch (e) {
				reject(e);
			}
		});

		const errorsAreAllNull = (errors: FormError): boolean => {
			return !(
				errors.email ||
				errors.codeChallenge ||
				errors.agreeToTerms
			);
		};

		if (!errorsAreAllNull(errors)) {
			return this.setState(state => ({ ...state, errors }));
		}

		const { handleRedirect } = this.props;
		const { developerId } = this.state;

		this.setState({ loading: true });

		/**
		 * Let's handle the case where the upload times out.
		 * Netlify functions have a max execution time of 10 seconds.
		 * If the upload takes longer, kill the request and tell the user.
		 */

		const requestStartedAt = new Date().getTime();
		const cancelNetlifyRequestController = new AbortController();
		const timeoutInterval = setInterval(() => {
			const timeSinceRequestStart =
				new Date().getTime() - requestStartedAt;

			if (timeSinceRequestStart >= 9000) {
				cancelNetlifyRequestController.abort();
				clearInterval(timeoutInterval);
				this.setState({
					formErrorMsg: <UploadTimedOutErrorMessage />,
				});
				this.setState({ loading: false });
			}
		}, 1000);

		try {
			// Upload the file
			const { url: codeUrl } = await fetch(
				String(process.env.REACT_APP_CODE_UPLOAD_ENDPOINT),
				{
					method: 'POST',
					signal: cancelNetlifyRequestController.signal,
					body: JSON.stringify({
						name: `${developerId}/${this.state.assessmentId}/code.zip`,
						bucket: 'code.g2i.co',
						content: fileContent,
					}),
				}
			).then(r => r.json());

			// Update the developer assessment
			await fetchFromHasura({
				query: this.props.developerAssessmentId
					? updateCodeUrlForExistingAssessment
					: updateCodeUrlForNewAssessment,
				variables: {
					id: this.props.developerAssessmentId,
					developerId: String(developerId),
					assessmentId: this.state.assessmentId,
					assignedToId: null,
					codeUrl,
				},
			});

			this.setState({ loading: false });
			handleRedirect();
		} catch (e) {
			const maybeGraphQLErrors =
				e.response?.data?.error?.response?.errors;
			const error = maybeGraphQLErrors
				? maybeGraphQLErrors.map((e: Error) => e.message).join(`\n`)
				: e.message || e;

			if (maybeGraphQLErrors) {
				// Handle an error from the server here.
				this.setState({
					formErrorMsg: error,
				});
			}
			this.setState({ loading: false });
		}
	};

	_onChangeInput = (name: string, value: string | File | boolean | null) => {
		this.setState(state => ({
			...state,
			[name]: value,
			// We should also reset any form input errors for the given name
			errors: { ...state.errors, [name]: null },
		}));
	};

	validateEmail = (_name: string, emailValue: string) => {
		getUserFromEmail(emailValue).then(({ data: { developers } }) => {
			if (developers.length === 0) {
				return this.setState({
					errors: {
						...this.state.errors,
						email:
							'This email does not exist in our database, please use the same email you used to apply or apply at https://g2i.netlify.app/apply',
					},
				});
			}
			this.setState({ developerId: developers[0].id });
		});
	};

	togglePrivacyPolicyModal = () =>
		this.setState({ privacyPolicyModal: !this.state.privacyPolicyModal });

	toggleNDAModal = () =>
		this.setState({ ndaPolicyModal: !this.state.ndaPolicyModal });

	render() {
		const {
			email,
			formErrorMsg,
			codeChallenge,
			errors,
			loading,
			agreeToTerms,
			privacyPolicyModal,
			ndaPolicyModal,
		} = this.state;

		const canSubmit = RequiredFields.every(
			val => !!this.state[val as keyof State]
		);

		return (
			<FormEl>
				{this.props.generic && (
					<>
						<VisuallyHidden>
							<label htmlFor="email">Email</label>
						</VisuallyHidden>
						<TextInput
							value={email}
							name="email"
							onChange={this._onChangeInput}
							errors={errors}
							placeholder="Email"
							onBlur={this.validateEmail}
						/>
						<FieldGroup>
							<label>Code Challenge Type</label>
							<Select
								name="code-challenge-type"
								errors={errors}
								value={this.state.assessmentId}
								placeholder="Please choose a code challenge type"
								onChange={(_, val) =>
									this.setState(p => ({
										...p,
										assessmentId: val,
									}))
								}
							>
								{this.state.assessments.map(a => (
									<option key={a.value} value={a.value}>
										{a.label}
									</option>
								))}
							</Select>
						</FieldGroup>
						<br />
					</>
				)}

				<VisuallyHidden>
					<label htmlFor="codeChallenge">Code Challenge Upload</label>
				</VisuallyHidden>
				<FileUpload
					name="codeChallenge"
					value={codeChallenge}
					onChange={this._onChangeInput}
					acceptableMimeTypes={[
						'zip',
						'x-compressed',
						'x-zip-compressed',
						'x-zip',
					]}
					errors={errors}
				/>
				<VisuallyHidden>
					<label htmlFor="agreeToTerms">
						Do you agree to be bound by our {''}
						<span
							className="link-text"
							onClick={this.toggleNDAModal}
						>
							NDA
						</span>{' '}
						and{' '}
						<span
							className="link-text"
							onClick={this.togglePrivacyPolicyModal}
						>
							privacy policy
						</span>
						.
					</label>
				</VisuallyHidden>
				<CheckBoxInput
					label={
						<span>
							Do you agree to be bound by our {''}
							<span
								className="link-text"
								onClick={this.toggleNDAModal}
								style={{
									cursor: 'pointer',
									textDecoration: 'underline',
								}}
							>
								NDA
							</span>{' '}
							and{' '}
							<span
								className="link-text"
								onClick={this.togglePrivacyPolicyModal}
								style={{
									cursor: 'pointer',
									textDecoration: 'underline',
								}}
							>
								privacy policy
							</span>
							?
						</span>
					}
					dataCy="agree-to-terms-toggle"
					name="agreeToTerms"
					value={agreeToTerms}
					onChange={this._onChangeInput}
					errors={errors}
				/>
				<div style={{ marginTop: 32 }}>
					<Button
						dataCy="upload-challenge-button"
						text={loading ? 'Submitting...' : 'Upload Code Sample'}
						onClick={this._onSubmitForm}
						disabled={
							loading ||
							!canSubmit ||
							Object.values(errors).some(val => val?.length)
						}
					/>
				</div>
				<PrivacyPolicyModal
					modal={privacyPolicyModal}
					onDismiss={this.togglePrivacyPolicyModal}
				/>
				<NDAModal
					modal={ndaPolicyModal}
					onDismiss={this.toggleNDAModal}
				/>
				<div>
					{formErrorMsg && (
						<p className=" form-small-text form-error-text">
							<Suspense fallback="Loading error...">
								{formErrorMsg}
							</Suspense>
						</p>
					)}
				</div>
			</FormEl>
		);
	}
}
