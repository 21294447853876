import React from 'react';
import styled from 'styled-components';
import checkmarkIcon from '../assets/checkmark_icon.svg';

type CheckBoxElProps = {
	checked: boolean;
	dataCy?: string;
};

const CheckBoxEl = styled.div`
	width: 36px;
	height: 16px;
	border-radius: 12px;
	border-width: 2px;
	border-style: solid;
	border-color: ${(p: CheckBoxElProps) =>
		p.checked ? '#2c7ef8' : '#D9E1EC'};
	background-color: ${(p: CheckBoxElProps) =>
		p.checked ? '#2c7ef8' : '#D9E1EC'};
	background-image: url(${checkmarkIcon});
	background-repeat: no-repeat;
	background-position: 5px center;
	position: relative;
	cursor: pointer;

	div {
		width: 16px;
		height: 16px;
		border-radius: 50%;
		background-color: white;
		position: absolute;
		right: ${(p: CheckBoxElProps) => (p.checked ? '0px' : 'auto')};
	}
`;

export const CheckBox: React.FunctionComponent<{
	checked?: boolean;
	dataCy?: string;
	onChange: () => void;
}> = ({ checked = false, onChange, dataCy }) => (
	<CheckBoxEl
		tabIndex={0}
		dataCy={dataCy}
		checked={checked}
		onClick={onChange}
		onKeyUp={e => {
			if ([' ', 'space'].includes(e.key.toLowerCase())) {
				onChange();
			}
		}}
	>
		<div />
	</CheckBoxEl>
);
