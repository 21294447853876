import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent } from '@reach/dialog';
import VisuallyHidden from '@reach/visually-hidden';

const DialogWithStyles = styled(Dialog)`
  background: hsla(0, 0%, 0%, 0.33);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;

  .close-button {
    align-self: flex-end;
    background: white;
    border: none;
    cursor: pointer;
    font-size: 2em;
    max-width: 50px;
    outline: none;
  }
`;

const DialogContentWithStyles = styled(DialogContent)`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;

  width: 50vw;
  margin: 10vh auto;
  background: white;
  padding: 2rem;
  outline: none;
`;

interface NDAModalProps {
  modal: boolean;
  onDismiss: () => void;
}

const NDAModal: React.FC<NDAModalProps> = ({ modal, onDismiss }) => (
  <DialogWithStyles isOpen={modal} onDismiss={onDismiss}>
    <DialogContentWithStyles>
      <button className="close-button" onClick={onDismiss}>
        <VisuallyHidden>Close</VisuallyHidden>
        <span aria-hidden>×</span>
      </button>
      <h1>G2i Non-Disclosure Agreement</h1>
      <p>
        This Non-Disclosure Agreement <i>(Agreement)</i> is effective on the
        date of the last signature of any party to this Agreement and is between
        you, an independent contractor and G2i,Inc., a Florida corporation{' '}
        <i>(Company)</i>.
      </p>

      <h3>1. Recitals.</h3>
      <p>
        In connection with the performance of services by the Company to the
        Customer (Transaction), each party acknowledges that it will have access
        to Confidential Information of the other party. Each party further
        acknowledges that the other party:
      </p>
      <ul>
        <li>
          has invested and continues to invest significant time, expense, and
          specialized knowledge in developing this Confidential Information;
        </li>

        <li>
          enjoys a competitive advantage in the marketplace based on the
          Confidential Information; and would be irreparably harmed if
          competitors obtained the Confidential Information or if it became
          publicly available.
        </li>
      </ul>
      <p>The parties therefore agree as follows.</p>

      <h3>2. Non-Disclosure Agreement</h3>
      <p>
        Without limiting the applicability of any other agreement to which
        either party is subject, neither party may directly or indirectly
        disclose or use any Confidential Information at any time during or after
        the Transaction. This restriction includes the use of Confidential
        Information for personal, commercial, or proprietary advantage or
        profit. Each party possessing Confidential Information shall take all
        appropriate steps to safeguard the information and to protect it against
        disclosure, misuse, espionage, loss, and theft. Upon either party’s
        request, the other party will promptly return or destroy all copies of
        the Confidential Information.
      </p>

      <h3>3. Confidential Information.</h3>
      <p>
        For purposes of this Agreement, <i>Confidential Information</i> means
        trade secrets, proprietary information, and other information belonging
        to either party that are not generally known to the public, including
        information about business plans, financial statements, and other
        information provided under this Agreement, operating practices and
        methods, expansion plans, strategic plans, marketing plans, contracts,
        customer lists, or other business documents that either party treats as
        confidential, in any format whatsoever including oral, written, and
        electronic. Examples of Confidential Information include the items on
        the following list, which is not exhaustive:
      </p>
      <ul>
        <li>
          all information, formulae, compilations, software programs (including
          object codes and source codes), devices, methods, techniques,
          drawings, plans, experimental and research work, inventions, patterns,
          processes and know-how — whether or not patentable and whether or not
          at a commercial stage — related to either party;
        </li>

        <li>
          the names, buying habits, or practices of any customers of either
          party;
        </li>

        <li>marketing methods and related data of either party;</li>

        <li>the names of any vendors or suppliers of either party;</li>

        <li>the cost of materials to either party;</li>

        <li>
          the prices either party obtains or has obtained or at which it sells
          or has sold its products or services;
        </li>

        <li>
          lists or other written records used in the business of either party;
        </li>

        <li>
          compensation paid to employees and other employment terms of either
          party;
        </li>

        <li>
          all information that either party has a legal obligation to treat as
          confidential or that either party treats as proprietary;
        </li>

        <li>
          or any other confidential information concerning the business of
          either party, their manners of operation, or other confidential data
          of any kind, nature, or description.
        </li>
      </ul>

      <h3>4. Certain Information Not Considered Confidential.</h3>
      <p>
        The restrictions of Section 2 do not apply to Confidential Information
        that:
      </p>
      <ul>
        <li>
          is or becomes generally available to the public other than as a result
          of a disclosure by a party inviolation of this Agreement;
        </li>

        <li>
          is or becomes available to a party on a nonconfidential basis before
          its disclosure to a party in compliance with this Agreement;
        </li>

        <li>
          or is or has been independently developed or conceived by a party
          without using Confidential Information.
        </li>
      </ul>

      <h3>5. Permitted Disclosures.</h3>
      <p>
        Nothing in Section 2 prevents either party from disclosing Confidential
        Information:
      </p>
      <ul>
        <li>
          upon the order of any court or administrative agency, upon the request
          or demand of any regulatory agency or authority having jurisdiction
          over the party, or to the extent compelled by legal process or
          required or requested under subpoena, interrogatories, or other
          discovery requests;
        </li>

        <li>
          as necessary in connection with exercising any remedy under this
          Agreement;
        </li>

        <li>
          or in connection with the Transaction, to the party’s legal counsel
          and accountants who, in the party’s reasonable judgment, need to know
          the Confidential Information and agree to be bound by the provisions
          of Section 2 as if a party.
        </li>
      </ul>

      <p>
        Neither party may make any disclosure permitted by this Section (other
        than disclosure to the party’s own legal counsel in connection with the
        Transaction) before notifying the other party as far in advance of the
        disclosure as practicable. Notice to the other party must state the
        purpose of the disclosure and the means taken to ensure that any
        disclosed Confidential Information remains confidential.
      </p>

      <h3>6. No Public Disclosure of Agreement.</h3>
      <p>
        Unless authorized in writing by the other party or required (or believed
        in good faith to be required) by any law, regulation, rule, court, or
        other governmental body, neither party may make any public disclosure
        about this Agreement’s subject matter; about past, present, or future
        discussions about the Agreement; or that this Agreement even exists.
      </p>

      <h3>7. Modification for Legal Events.</h3>
      <p>
        If any court of competent jurisdiction determines that any provision or
        any part of a provision set forth in this Agreement is unenforceable
        because of its duration or geographic scope, the court has the power to
        modify the unenforceable provision instead of severing it from this
        Agreement in its entirety.
      </p>
      <p>
        The modification may be by rewriting the offending provision, by
        deleting all or a portion of the offending provision, by adding
        additional language to this Agreement, or by making other modifications
        as it determines necessary to carry out the parties’ intent to the
        maximum extent permitted by Applicable Law. The parties expressly agree
        that this Agreement as modified by the court is binding upon and
        enforceable against each of them.
      </p>
      <h3>8. General Matters.</h3>
      <h4>(a) No Representation or Warranty as to Accuracy</h4>
      <p>
        Each party acknowledges that neither party nor their respective
        representatives make any representation or warranty as to the accuracy
        or completeness of the Confidential Information. Any representations or
        warranties will be in a separate, definitive agreement between the
        parties.
      </p>

      <h4>(b) Further Assurances</h4>
      <p>
        In connection with this Agreement and the transactions contemplated by
        it, each party agrees to provide further assurances if requested by the
        other party. These further assurances include signing and delivering any
        additional documents, instruments, conveyances, and other assurances or
        taking any further actions necessary to carry out the provisions of or
        transactions contemplated by this Agreement.
      </p>

      <h4>(c) No Waiver</h4>
      <p>
        A party’s failure to insist upon strict performance of any provision or
        obligation of this Agreement for any period of time is not a waiver of
        that party’s right to demand strict compliance in the future. An express
        or implied consent to or waiver of any breach or default in the
        performance of any obligations under this Agreement is not a consent to
        or waiver of any other breach or default in the performance of the same
        or of any other obligation.
      </p>
      <h4>(d) Governing Law</h4>
      <p>
        This Agreement is governed, construed, and administered according to the
        laws of the State of Florida, as from time to time amended, and any
        applicable federal law. No effect is given to any choice-of-law or
        conflict-of-law provision or rule (whether of the State of Florida or
        any other jurisdiction) that would cause the application of laws of any
        jurisdiction other than those of the State of Florida.
      </p>
      <h4>(e) Venue; Submission to Jurisdiction</h4>
      <p>
        A cause of action arising out of this Agreement includes any cause of
        action seeking to enforce any provision of or based on any matter
        arising out of or in connection with this Agreement or the transactions
        contemplated by it. The parties agree that any suit, action, or
        proceeding — whether in contract, tort, or otherwise — arising out of
        this Agreement must be brought in a state or federal court or courts
        located inthe State of Florida if one of these courts has subject -
        matter jurisdiction over the suit, action, or proceeding. Any cause of
        action arising out of this Agreement is deemed to have arisen from a
        transaction of business in the State of Florida.
      </p>
      <p>
        Each party irrevocably consents to the jurisdiction of these courts (and
        their respective appellate courts) in any cause of action arising out of
        this Agreement. To the fullest extent permitted by law, each party
        irrevocably waives any objection that it may have now or later to the
        venue of any action arising out of this Agreement in any of these
        courts, including an inconvenient forum petition.
      </p>
      <p>
        Service of process, summons, notice, or other document by registered
        mail to the address designated in Section 8(j) is effective service of
        process for any suit, action, or other proceedingbrought in any court.
      </p>
      <h4>(f) Waiver of Jury Trial</h4>
      <p>
        Each party to this Agreement acknowledges and agrees that any
        controversy arising out of thisAgreement is likely to involve
        complicated issues. Therefore, each party irrevocably and
        unconditionally waives any right it may have to a trial by jury for any
        cause of action arising out ofthis Agreement.
      </p>
      <h4>(g) Equitable Remedies</h4>
      <p>
        Each party to this Agreement acknowledges that its breach or threatened
        breach of any of its obligations under this Agreement would give rise to
        irreparable harm to the other parties andmonetary damages would not be
        an adequate remedy. Therefore, each party to this Agreement agrees that
        if any party breaches or threatens to breach any of its obligations,
        each of the otherparties to this Agreement will be entitled to equitable
        relief, including a temporary restraining order,an injunction, specific
        performance, and any other equitable relief available from a court of
        competent jurisdiction (without any requirement to post bond). These
        equitable remedies are in addition to all other rights and remedies that
        may be available in respect of the breach.
      </p>
      <h4>(h) Attorneys’ Fees</h4>
      <p>
        If any party to this Agreement institutes any legal cause of
        action—including arbitration—against another party arising out of or
        relating to this Agreement, the prevailing party will be entitled to the
        costs incurred in conducting the cause of action, including reasonable
        attorneys’ fees and expenses and court costs.
      </p>
      <h4>(i) Remedies Cumulative</h4>
      <p>
        Except to the extent this Agreement expressly provides otherwise, the
        rights and remedies underthis Agreement are cumulative and are in
        addition to and not in substitution for any other rights andremedies
        available at law, in equity, or otherwise.
      </p>
      <h4>(j) Notices</h4>
      <p>
        Unless otherwise stated, all notices, requests, consents, claims,
        demands, waivers, and other communications called for under this
        Agreement must be in writing and will be considered given:
      </p>
      <ul>
        <li>when delivered by hand (with written confirmation of receipt);</li>
        <li>
          when received by the addressee if sent by a nationally recognized
          overnight courier (receipt requested);
        </li>
        <li>
          on the date sent by facsimile or email as a PDF document (with
          confirmation of transmission) if sent during recipient’s normal
          business hours, and on the next business day if sent after normal
          business hours of the recipient; or
        </li>
        <li>
          on the third day after the date mailed, by certified or registered
          mail, return receipt requested, postage prepaid.
        </li>
      </ul>
      <p>
        The written notice must be sent to the respective parties at the party’s
        last known address (or atthe address a party has specified in a notice
        given in accordance with this Section).
      </p>
      <h4>(k) Severability</h4>
      <p>
        The invalidity or unenforceability of any provision of this Agreement
        does not affect the validity or enforceability of any other provision of
        this Agreement. If a court of competent jurisdiction determines that any
        provision is invalid, the remaining provisions of this Agreement are to
        be construed as if the invalid provision had never been included in this
        Agreement.
      </p>
      <p>
        Subject to Section 6, upon a determination that any provision is
        invalid, illegal, or unenforceable, the parties to this Agreement shall
        negotiate in good faith to modify this Agreement to effect the original
        intent of the parties as closely as possible in a mutually acceptable
        manner so that the transactions contemplated by this Agreement can be
        consummated as originally contemplated tothe greatest extent possible.
      </p>
      <h4>(l) Separate Counsel</h4>
      <p>
        By signing this Agreement, each party acknowledges that this Agreement
        is the product of arms-length negotiations between the parties and
        should be construed as such. Each party acknowledges that he or she has
        been advised to seek separate counsel and has had adequateopportunity to
        do so. No party may claim that an ambiguity in this Agreement should be
        construedagainst any other party or that there was any coercion, duress
        (economic or otherwise), negligent misrepresentation, or fraud
        (including fraud in the inducement) affecting the validity or
        enforcement of this Agreement.
      </p>

      <h4>(m) Entire Agreement</h4>
      <p>
        This Agreement constitutes the sole and entire agreement of its parties
        with respect to the Agreement’s subject matter. This Agreement
        supersedes all prior and contemporaneous understandings, agreements,
        representations, and warranties with respect to the subject matter. As
        between or among the parties, no oral statements or prior written
        material not specifically incorporated in this Agreement has any force
        or effect. The parties specifically acknowledge that, in entering into
        and executing this Agreement, each is relying solely upon the
        representations and agreements in this Agreement and no others.
      </p>
      <h4>(n) Amendments</h4>
      <p>
        No provision of this Agreement may be amended or modified except by a
        written instrument executed by the parties.
      </p>
      <h4>(o) Multiple Originals; Validity of Copies</h4>
      <p>
        This Agreement may be signed in any number of counterparts, each of
        which will be deemed an original. Any person may rely on a copy of this
        Agreement that any party certifies to be a true copy to the same effect
        as if it were an original.
      </p>
    </DialogContentWithStyles>
  </DialogWithStyles>
);

export default NDAModal;
