import * as React from 'react';
import styled from 'styled-components';

type ButtonElProps = {
	whiteOutlined?: boolean;
	backgroundImageUrl?: string;
	disabled: boolean;
	dataCy?: string;
	onClick: () => void;
};

const ButtonEl = styled.a.attrs<ButtonElProps>(({ type }) => ({
	type,
}))`
	color: ${({ whiteOutlined }) => (whiteOutlined ? '#3B4660' : '#fff')};
	cursor: ${(p: ButtonElProps) => (p.disabled ? 'not-allowed' : 'pointer')};
	font-size: 16px;
	letter-spacing: -0.2;
	font-weight: 700;
	background-color: ${(p: ButtonElProps) =>
		p.whiteOutlined ? 'white' : p.disabled ? '#E9EFF7' : '#2c7ef8'};

	border: ${({ whiteOutlined }: ButtonElProps) =>
		whiteOutlined ? '1px solid #D9E1EC' : '1px solid transparent'};
	border-radius: 38px;
	line-height: 19px;
	padding: 15px 121px 16px 123px;
	background-image: ${({ backgroundImageUrl }: ButtonElProps) =>
		backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none'};
	background-repeat: no-repeat;
	background-position: 25px center;
	display: block;
	text-align: center;
	text-decoration: none !important;
	transition: 0.2s ease-in-out;

	&:hover {
		background-color: ${(p: ButtonElProps) =>
			p.disabled ? '#E9EFF7' : '#0e64e4'};
	}
`;

ButtonEl.defaultProps = { role: 'button', tabIndex: 0 };

export const Button: React.FunctionComponent<{
	disabled?: boolean;
	text: string;
	iconSrc?: string;
	whiteOutlined?: boolean;
	href?: string;
	dataCy?: string;
	onClick: (e?: React.MouseEvent | React.KeyboardEvent) => void;
}> = ({
	onClick,
	iconSrc,
	whiteOutlined = false,
	text,
	disabled = false,
	href,
	dataCy,
}) => {
	// If disabled we pass a dud function
	const onButtonClick = disabled
		? () => {
				return;
		  }
		: onClick;

	return (
		<ButtonEl
			dataCy={dataCy}
			whiteOutlined={whiteOutlined}
			backgroundImageUrl={iconSrc}
			disabled={disabled}
			onClick={onButtonClick}
			onKeyUp={e => {
				if ([' ', 'space'].includes(e.key.toLowerCase())) {
					onClick(e);
				}
			}}
			href={href}
		>
			{text}
		</ButtonEl>
	);
};
