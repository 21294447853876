import React, { Component } from 'react';
import styled from 'styled-components';
import * as Sentry from '@sentry/browser';

const MISSING_ERROR = 'Error was swallowed during propagation.';

const WrapperEl = styled.div`
  margin-top: 24px;
  background: #f1f1f1;
  border-radius: 3px;
  padding: 8px 24px;
  p {
    margin: 0;
    margin-bottom: 8px;
    font-size: 90%;
    text-align: center;
    font-weight: bold;
    color: #d34d4d;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

class ErrorBoundary extends Component {
  state = {
    eventId: undefined,
    hasError: false,
  };

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error | null, errorInfo: object) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(
        error || new Error(MISSING_ERROR),
      );
      this.setState({ eventId, hasError: true });
    });
  }

  render() {
    // Render fallback UI in case of error or render children untouched
    if (this.state.hasError) {
      return (
        <WrapperEl>
          <p>We're sorry — something's gone wrong!</p>
          <p>
            Our team's been notified and will resolve the issue as soon as
            possible
          </p>
        </WrapperEl>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
