import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { Card, CardHeader, Logo, PageWrapper } from '../components';
import { UploadForm } from '../components/UploadForm';
import PrivacyPolicyModal from '../components/PrivacyPolicyModal';
import NDAModal from '../components/NDAModal';
import ErrorBoundary from '../components/ErrorBoundary';

const UploadPageEl = styled.div`
	margin: auto;
	width: max-content;
`;

interface Props extends RouteComponentProps {
	user: {
		_id: string;
		email: string;
		display_name: string;
	} | null;
}

const UploadPage: React.FC<Props> = ({ history, user }) => {
	const [privacyPolicyModal, togglePrivacyPolicyModal] = useState(false);
	const [ndaModal, toggleNDAModal] = useState(false);

	const limitInMb = Number(process.env.REACT_APP_FILE_SIZE_LIMIT) / 1000000;
	const fileSizeLimit = isNaN(limitInMb) ? false : limitInMb;

	const renderBodyText = () => (
		<p className="page-subtitle">
			Please upload a zipped version of your project here and be sure not
			to include the node_modules folder.
			{fileSizeLimit &&
				` Maximum upload file size allowed is ${fileSizeLimit}MB.`}
		</p>
	);

	const handleRedirect = () => history.push('/upload_success');

	return (
		<PageWrapper>
			<PrivacyPolicyModal
				modal={privacyPolicyModal}
				onDismiss={() => togglePrivacyPolicyModal(false)}
			/>
			<NDAModal
				modal={ndaModal}
				onDismiss={() => toggleNDAModal(false)}
			/>
			<UploadPageEl>
				<Card>
					<CardHeader
						icon={Logo}
						titleText="Upload Your Code Challenge"
						renderBodyText={renderBodyText}
					/>
					<ErrorBoundary>
						<UploadForm
							generic
							user={user}
							handleRedirect={handleRedirect}
						/>
					</ErrorBoundary>
				</Card>
			</UploadPageEl>
		</PageWrapper>
	);
};

export default UploadPage;
