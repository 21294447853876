import styled from 'styled-components';

export const FieldGroup = styled.div<{ columns?: number }>`
	display: grid;
	grid-template-columns: ${({ columns }) => `repeat(${columns || 1}, 1fr)`};
	grid-template-rows: min-content auto;
	gap: 8px ${({ columns }) => (columns && columns > 1 ? `16px` : '0')};

	> label:first-of-type {
		grid-column: 1 / span 2;
		font-size: 0.9rem;
		font-weight: 600;
		color: #708096;
	}
`;
