import React from 'react';

import { FormError } from '../react-app-env';
import { InputErrorText } from './InputErrorText';
import { InputEl } from './TextInput';

export const Select: React.FC<{
	value: string;
	name: string;
	onChange: (name: string, value: string) => void;
	errors: FormError;
	placeholder: string;
}> = ({ value, onChange, name, errors, placeholder, children }) => {
	return (
		<InputEl hasErrors={!!errors[name]}>
			<select
				name={name}
				id={name}
				value={value}
				onChange={e => onChange(name, e.target.value)}
				placeholder={placeholder}
			>
				{children}
			</select>
			{errors[name] && <InputErrorText>{errors[name]}</InputErrorText>}
		</InputEl>
	);
};
