import * as React from 'react';
import logo from '../assets/g2i-logo.svg';

export const Logo: React.FunctionComponent<{
  width?: number;
  height?: number;
}> = ({ width = 64, height = 64 }) => (
  <img
    alt="red circle with g2i letters in white"
    src={logo}
    style={{ width: width, height: height }}
  />
);
