import * as React from 'react';
import styled from 'styled-components';

const WrapperEl = styled.div`
  h2.page-title {
    color: #3b4660;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -1.56;
    margin: 16px 0;
    text-align: center;
  }

  p.page-subtitle {
    color: #708096;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -0.22;
    line-height: 24px;
    margin: 0 auto;
    text-align: center;
    width: 331px;

    a.link-text {
      color: #2c7ef8;
      cursor: pointer;
      text-decoration: none !important;
    }
  }
`;

interface CardHeaderProps {
  icon: React.SFC;
  renderBodyText: () => {};
  titleText: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  icon: IconComponent,
  renderBodyText,
  titleText,
}) => (
  <WrapperEl>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <IconComponent />
    </div>
    <h2 className="page-title">{titleText}</h2>
    {renderBodyText()}
  </WrapperEl>
);
