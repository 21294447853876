import React from 'react';
import styled from 'styled-components';
import { InputErrorText } from '.';
import { FormError } from '../react-app-env';
import { CheckBox } from './CheckBox';

const CheckBoxInputWrapper = styled.div`
	position: relative;

	.checkbox-error-text {
		position: absolute;
		bottom: -16px;
	}
`;

const CheckBoxInputEl = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #495572;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 16px;

	p {
		margin: 0;
	}
`;

type Props = {
	name: string;
	value: boolean;
	dataCy?: string;
	label: string | React.ReactNode;
	onChange: (name: string, value: boolean) => void;
	errors: FormError;
};

export const CheckBoxInput: React.FC<Props> = ({
	errors,
	label,
	name,
	onChange,
	value,
	dataCy,
}) => {
	const _onClickCheckBox = () => onChange(name, !value);

	return (
		<CheckBoxInputWrapper>
			<CheckBoxInputEl>
				<p>{label}</p>
				<CheckBox
					dataCy={dataCy}
					onChange={_onClickCheckBox}
					checked={value}
				/>
			</CheckBoxInputEl>
			{errors[name] && (
				<InputErrorText className="checkbox-error-text">
					{errors[name]}
				</InputErrorText>
			)}
		</CheckBoxInputWrapper>
	);
};
