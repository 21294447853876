import VisuallyHidden from '@reach/visually-hidden';
import * as React from 'react';
import styled from 'styled-components';
import { InputErrorText } from '.';
import { COLORS } from '../constants';
import { FormError } from '../react-app-env';

export const InputEl = styled.div`
	margin-bottom: 20px;

	input,
	select {
		width: 100%;
		height: 52px;
		border-radius: 4px;
		border: ${({ hasErrors }: { hasErrors: boolean }) =>
			hasErrors ? `2px solid ${COLORS.errorColor}` : '1px solid #d9e1ec'};
		padding: 14px 15px;
		font-size: 16px;
		font-weight: 500;
		color: #3b4660;
		box-sizing: border-box;
		background-color: ${({ hasErrors }: { hasErrors: boolean }) =>
			hasErrors && `${COLORS.secondaryErrorColor}`};
	}
`;

const TextInput: React.FC<{
	name: string;
	value: string;
	onChange: (name: string, value: string) => void;
	onBlur?: (name: string, value: string) => void;
	errors: FormError;
	placeholder: string;
}> = ({ name, value, onChange, errors, placeholder, onBlur = undefined }) => {
	return (
		<InputEl hasErrors={!!errors[name]}>
			<VisuallyHidden>
				<label htmlFor={name}>First Name</label>
			</VisuallyHidden>
			<input
				name={name}
				id={name}
				value={value}
				onChange={e => onChange(name, e.target.value)}
				placeholder={placeholder}
				onBlur={onBlur && (e => onBlur(name, e.target.value))}
			/>
			{errors[name] && <InputErrorText>{errors[name]}</InputErrorText>}
		</InputEl>
	);
};

export { TextInput };
