import styled from 'styled-components';

export const Card = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 35px 40px 40px;
  box-shadow: 0 1px 3px 0 rgba(30, 40, 52, 0.12);
  width: 480px;
  max-width: 480px;
`;
