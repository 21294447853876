export const updateCodeUrlForExistingAssessment = `mutation updateCodeUrlForExistingAssessment($assignedToId: uuid, $id: uuid!, $developerId: uuid!, $assessmentId: uuid!, $codeUrl: String!) {
    insert_developer_assessments(objects: [{assigned_to_id: $assignedToId, developer_id: $developerId, id: $id, assessment_id: $assessmentId, code_url: $codeUrl, updated_at: "now()"}], on_conflict: {constraint: developer_assessments_pkey, update_columns: [code_url, assigned_to_id]}) {
      returning {
        id
      }
    }
    update_developers(_set: {stage: VETTING, status: VETTING}, where: {id: {_eq: $developerId}}) {
      affected_rows
    }
  }					  
`;
