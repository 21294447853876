import styled from 'styled-components';
import { COLORS } from '../constants';

export const InputErrorText = styled.p`
  margin: 0;
  margin-top: 5px;
  font-weight: 500;
  letter-spacing: -0.2;
  line-height: 24px;
  font-size: 14px;
  color: ${COLORS.errorColor};
`;
