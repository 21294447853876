import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import UploadPage from './pages/Upload';
import UploadSuccessPage from './pages/UploadSuccess';
import WrongPage from './pages/WrongPage';
import GenericUpload from './pages/GenericUpload';

const App: React.SFC = () => (
	<BrowserRouter basename="/code-upload">
		<Switch>
			<Route
				exact
				path="/upload/:developerId/:assessmentId"
				component={UploadPage}
			/>
			<Route exact path="/upload" component={GenericUpload} />
			<Route exact path="/upload_success" component={UploadSuccessPage} />
			<Route exact path="/wrong_page" component={WrongPage} />
			<Redirect to="/upload" />
		</Switch>
	</BrowserRouter>
);

export default App;
