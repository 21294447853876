import * as React from 'react';
import styled from 'styled-components';

import successIcon from '../assets/success_icon.svg';
import { Card, CardHeader, PageWrapper } from '../components';

const SuccessPageEl = styled.div`
	padding-top: 150px;
	margin: auto;
	width: max-content;
`;

const renderIcon = () => (
	<img
		data-cy="upload-success"
		src={successIcon}
		alt="success"
		style={{ width: 64, height: 64 }}
	/>
);

const UploadSuccessPage = () => (
	<PageWrapper>
		<SuccessPageEl>
			<Card>
				<CardHeader
					icon={renderIcon}
					titleText="Thank you!"
					renderBodyText={() => (
						<p className="page-subtitle">
							We’ll review your code and get back to you. For the
							next step,{' '}
							<a href={process.env.REACT_APP_CALENDLY_LINK}>
								schedule a technical interview
							</a>{' '}
							at your earliest convenience.
						</p>
					)}
				/>
			</Card>
		</SuccessPageEl>
	</PageWrapper>
);

export default UploadSuccessPage;
