import styled from 'styled-components';
import { COLORS } from '../constants';

export const PageWrapper = styled.div`
  min-height: 100vh;
  background-color: ${COLORS.backgroundColor};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
